











































import { Component, Vue } from "vue-property-decorator";
import FormCard from "@/components/form/FormCard.vue";
import { Prop } from "vue-property-decorator";
import Notification from "@/entity/Notification";
import DuplicateContactExposeOrderContactDetails from "@/components/notifications/duplicate-contact-expose-order/DuplicateContactExposeOrderContactDetails.vue";
import NotificationsService from "@/services/NotificationsService";
import { capitalizeStart } from "@/utils/string";

@Component({
  methods: { capitalizeStart },
  components: {
    FormCard,
    DuplicateContactExposeOrderContactDetails,
  },
})
export default class DuplicateContactExposeOrderDialog extends Vue {
  @Prop({ required: true, type: Number }) entityId!: number;
  @Prop({ required: true, type: Array }) notifications!: Notification[];

  isSaving = false;

  get notification(): Notification | null {
    const notification = this.notifications.find(
      (notification) => notification.id === this.entityId
    );

    if (!notification) return null;

    return notification;
  }

  get notificationMeta(): Record<string, unknown> | null {
    if (!this?.notification?.meta) return null;

    return JSON.parse(this.notification.meta);
  }

  async handleEditExisting() {
    try {
      this.isSaving = true;
      await NotificationsService.duplicateExposeEditEmail(
        this.notification?.id as number
      );
      this.$snackbarSuccess(
        this.$t("duplicateContactExposeOrderDialog.editExisting.apiSuccess", {
          name: this.notification?.contact?.name,
        })
      );
      this.$emit("success");
    } catch (e) {
      this.$snackbarError(
        this.$tc("duplicateContactExposeOrderDialog.editExisting.apiError")
      );
    } finally {
      this.isSaving = false;
    }
  }
}
