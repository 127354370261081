
















































































import { Component, Vue } from "vue-property-decorator";
import { Prop } from "vue-property-decorator";

@Component
export default class DuplicateContactExposeOrderContactDetails extends Vue {
  @Prop({ required: true, type: Object }) notificationMeta!: Record<
    string,
    unknown
  >;
}
