var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('FormCard',{attrs:{"title":_vm.$t('duplicateContactExposeOrderDialog.title', {
      name: _vm.notification.contact.name,
    }),"is-saving":_vm.isSaving}},[_c('DuplicateContactExposeOrderContactDetails',{attrs:{"notification-meta":_vm.notificationMeta}}),_c('template',{slot:"actions"},[_c('v-card-actions',{staticClass:"px-8 pb-8"},[_c('v-spacer'),_c('div',[_c('v-btn',{staticClass:"mr-2 mb-2",attrs:{"color":"primary","loading":_vm.isSaving},domProps:{"textContent":_vm._s(
            _vm.capitalizeStart(
              _vm.$tc('duplicateContactExposeOrderDialog.editExisting')
            )
          )},on:{"click":_vm.handleEditExisting}}),_c('v-btn',{staticClass:"mr-2 mb-2",attrs:{"outlined":"","color":"primary"},domProps:{"textContent":_vm._s(
            _vm.capitalizeStart(_vm.$tc('duplicateContactExposeOrderDialog.ignore'))
          )},on:{"click":function($event){return _vm.$emit('cancel')}}})],1)],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }