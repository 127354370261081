











































































import { Component } from "vue-property-decorator";
import Notification from "@/entity/Notification";
import NotificationsList from "@/components/notifications/NotificationsList.vue";
import { State, Action, Mutation } from "vuex-class";
import DialogWrapper from "@/components/common/DialogWrapper.vue";
import ProjectFormCard from "@/components/project-form/ProjectFormCard.vue";
import DialogMixin from "@/mixins/DialogMixin";
import { Mixins } from "vue-property-decorator";
import { NotificationType } from "@/entity/Notification";
import DuplicateContactExposeOrderDialog from "@/components/notifications/duplicate-contact-expose-order/DuplicateContactExposeOrderDialog.vue";

@Component({
  components: {
    ProjectFormCard,
    DialogWrapper,
    NotificationsList,
    DuplicateContactExposeOrderDialog,
  },
})
export default class Notifications extends Mixins(DialogMixin) {
  isTooltipOpened = false;
  polling: any = null;

  @State("notifications", { namespace: "notifications" })
  notifications!: Notification[];
  @State("isMenuOpened", { namespace: "notifications" }) isMenuOpened!: boolean;
  @State("totalUnread", { namespace: "notifications" }) totalUnread!: number;
  @State("isLoading", { namespace: "notifications" }) isLoading!: boolean;
  @Action("dismissAll", { namespace: "notifications" })
  dismissAll!: Promise<void>;
  @Action("dismissNotification", { namespace: "notifications" })
  dismissNotification!: (id: number) => Promise<void>;
  @Action("fetchNotifications", { namespace: "notifications" })
  fetchNotifications!: () => Promise<void>;
  @Action("fetchTotalNotifications", { namespace: "notifications" })
  fetchTotalNotifications!: () => Promise<void>;
  @Mutation("setIsMenuOpened", { namespace: "notifications" })
  setIsMenuOpened!: (isOpened: boolean) => void;

  created() {
    this.fetchTotalNotifications();
    this.polling = setInterval(() => {
      this.fetchTotalNotifications();
    }, 30000);
  }

  navigateToHome(): void {
    if (this.$route.name !== "home") this.$router.push("/home");
    this.setIsMenuOpened(false);
  }

  get badgeValue(): string {
    return this.totalUnread > 9 ? "9+" : this.totalUnread.toString();
  }

  onNotificationClicked(notificationId: number): void {
    this.setIsMenuOpened(false);

    const clickedNotification = this.notifications.find(
      (notification) => notification.id === notificationId
    );

    if (
      clickedNotification?.type ===
      NotificationType.CONTACT_DUPLICATE_EXPOSE_ORDER
    ) {
      this.openDialog(
        NotificationType.CONTACT_DUPLICATE_EXPOSE_ORDER,
        notificationId
      );
    }
  }

  onMenuActivatorClick(): void {
    if (this.isMenuOpened) {
      this.setIsMenuOpened(false);
      this.isTooltipOpened = false;
      return;
    }

    this.fetchNotifications();
    this.setIsMenuOpened(true);
    this.isTooltipOpened = false;
  }

  beforeDestroy(): void {
    clearInterval(this.polling);
  }
}
